<div *ngIf="errorMessage"
     class="alert alert-danger">
  Error: {{ errorMessage }}
</div>

<div class="container furry_col">
    <div class="row align-items-center mt-4" >

        <div class="col-lg-6 " style="color: #E1CDA9;">
            <h4>Lorem Ipsum</h4>
            <span class="">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repellendus sed, natus earum eligendi aperiam facilis delectus, magni laboriosam voluptas iusto quod exercitationem dolorum ducimus. Vitae commodi unde aliquam repellat rerum eveniet quae in, eligendi magni
                voluptas esse saepe accusamus, facilis fugit eum incidunt et adipisci nostrum dolorum, explicabo consectetur sit!
            </span>
        </div>

        <div class="col-lg-6 card text-center mt-4" style="padding:40px 30px 60px 30px">
            <h4>Enter your phone number</h4>
            <p class="text-muted">One otp will be sent on your number</p>
            <form novalidate
                (ngSubmit)="sendOtp()"
                [formGroup]="otpForm">

                <div class="row">   
                    <div class="col-3" style="margin-right: inherit;">
                        <select
                        class="form-select"
                        formControlName="cc">
                                <option [value]="44" selected class="text-muted">+44</option>
                                <option [value]="91" class="text-muted">+91</option>
                        </select>
                    </div>
                    <div class="col-9">
                        <input type="phone" placeholder="9995180585"
                        class="form-control"
                        formControlName="phone">
                    </div>
                </div>
                <input type="submit" value="Next"
                class="form-control mt-3" style="background-color: rgb(53, 51, 51); color: white;">
           
            </form>
        </div>
    </div>
</div>