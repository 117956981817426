<div *ngIf="errorMessage"
     class="alert alert-danger">
  Error: {{ errorMessage }}
</div>

<div class="container furry_col">
    <div class="row align-items-center mt-4" >

        <div class="col-lg-6 " style="color: #E1CDA9;">
            <h4>Lorem Ipsum</h4>
            <span class="">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repellendus sed, natus earum eligendi aperiam facilis delectus, magni laboriosam voluptas iusto quod exercitationem dolorum ducimus. Vitae commodi unde aliquam repellat rerum eveniet quae in, eligendi magni
                voluptas esse saepe accusamus, facilis fugit eum incidunt et adipisci nostrum dolorum, explicabo consectetur sit!
            </span>
        </div>

        <div class="col-lg-6 card bg-white text-center mt-4" style="padding:40px 30px 60px 30px">
            <h4>Upload Profile</h4>

            <div class="d-flex flex-row">

                <ngx-dropzone (change)="onSelect($event)" class="p-2" style="width: 100%;">
                    <ngx-dropzone-label>
                        <img src="assets/images/upload.png" alt="" width="100">

                        <p>Drop and drop files to upload</p>
                    </ngx-dropzone-label>
                    <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                    </ngx-dropzone-preview>
                </ngx-dropzone>

            </div>    
            <input type="submit"
             (click)="setProfile()"   
             value="Next" class="form-control mt-3" 
             style="background-color: rgb(53, 51, 51); color: white;">
        </div>
    </div>
</div>