<div class="container mt-2">
    <div class="row" style="height:100vh; width: 100vw;">
      <div class="col-4 mt-4" style="color: #F9D591;">
         <h3 class="mb-5 mt-3">Peter is on the way</h3>
  
         <div class="row">
             <div class="col-1">
              <i class="fa-solid fa-location-crosshairs"></i>   
              <div class = "vertical"></div>
              <i class="fa-solid fa-location-crosshairs"></i> 
              <!-- <i class="fa-solid fa-map-pin"></i> -->
             </div>
             <div class="col-11">
              <p class="" style="color: #ecdec2;">Current location</p>
              <p>Shire Hall, Shepherd</p>
              <br>
              <p class="" style="color: #ecdec2;">Drop location</p>
              <p>Nimisham Campus</p>
             </div>
         </div>
  
         <div class="container card mt-3 p-1">
              <div class="row mt-2 " style="color:black">
                      <div class="col-6 card" style="position: relative;left: 10px;">
                          <img src="assets/images/image-passenger.jpg" alt="">
                          <div class="card-text ms-2">
                              <i class="fa-solid fa-star"></i>
                              <i class="fa-solid fa-star"></i>
                              <i class="fa-solid fa-star"></i>
                              <i class="fa-solid fa-star"></i>
                              <i class="fa-solid fa-star-half"></i>
                          </div>
                      </div>
  
                      <div class="col-6 " style="position: relative;left: 10px;">
                          <p>Kate Hudson</p>
                          <p>License No.</p>
                          <small>KL 11 BG 2717</small>
                          <p>Vehicle road</p>
                      </div>
              </div>
          </div>
  
         
      </div>
      <div class="col-8 ps-0" style="background-color: white;">
        <agm-map [latitude]="lat" [longitude]="lng"></agm-map>
      </div>
    </div>
  
  </div>