import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { Router } from '@angular/router';

import {OtpService} from '../otp-service';

@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.css']
})
export class VerifyOtpComponent implements OnInit {
  otpVerifyForm: FormGroup;
  errorMessage= '';
  constructor(private otpService: OtpService,
              private router: Router) { }

  ngOnInit(): void {
    this.otpVerifyForm = new FormGroup({          
      'otp1':new FormControl(null,[Validators.required, Validators.maxLength(1)]),
      'otp2':new FormControl(null,[Validators.required, Validators.maxLength(1)]),
      'otp3':new FormControl(null,[Validators.required, Validators.maxLength(1)]),
      'otp4':new FormControl(null,[Validators.required, Validators.maxLength(1)]),
    });
  }

  verifyOtp() {
    if (!this.otpVerifyForm.valid) {
      this.errorMessage = 'Please enter the OTP';
      return
    }
    const val = this.otpVerifyForm.value;
    const otp1 = val.otp1; const otp2 = val.otp2; 
    const otp3 = val.otp3; const otp4 = val.otp4;
    const otp = otp1+otp2+otp3+otp4;

    const otpId = localStorage.getItem('furry_otp_id');
    console.log(otpId);
    console.log('Saved: ' + JSON.stringify(this.otpVerifyForm.value));
    this.otpService.verifyOtp(otpId, otp)
    .subscribe({
      next: data => {
        this.otpService.setAccessToken(data.access_token);
        this.otpService.setRefreshToken(data.refresh_token);

        if (data.profile_completed) {
          console.log("verified")
          this.otpService.getKycStatus()
          .subscribe({
            next: data => {
              if (data.data.kyc_verification == 'incomplete') {
                this.router.navigateByUrl('/register/add-details');
              }
              console.log(data)
            },
            error: err => this.errorMessage = err.detail.msg
          })
        }
        
        this.router.navigateByUrl('/register/create-driver');
    },
      error: err => this.errorMessage = 'Wrong OTP entered'
  })
  

  }

  onDigitInput(event){

    let element;
    if (event.code !== 'Backspace')
         element = event.srcElement.nextElementSibling;
 
     if (event.code === 'Backspace')
         element = event.srcElement.previousElementSibling;
 
     if(element == null)
         return;
     else
         element.focus();
 }

}
