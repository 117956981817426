import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RegisterService } from '../../register.service';

@Component({
  selector: 'app-add-driver-details',
  templateUrl: './add-driver-details.component.html',
  styleUrls: ['./add-driver-details.component.css']
})
export class AddDriverDetailsComponent implements OnInit {
  kycForm: FormGroup;
  formData = new FormData();
  clicked = false;
  errorMessage = '';

  constructor(private kyc: RegisterService,
              private router: Router) { }

  ngOnInit(): void {
    this.kycForm = new FormGroup({
      'profile_pic': new FormControl(null, [Validators.required]),
      'dvla_ec_check_code': new FormControl(null, [Validators.required]),
      'id_proof': new FormControl(null, [Validators.required]),
      'national_insurance_no': new FormControl(null, [Validators.required]),
      'driving_license': new FormControl(null, [Validators.required]),
      'badge_number': new FormControl(null, [Validators.required]),
      'ph_pco_license': new FormControl(null, [Validators.required]),
      'white_plate_number': new FormControl(null, [Validators.required]),
    })
  }

  onFileChange(event) {
    const file = (event.target as HTMLInputElement).files[0];
    
    console.log("file", file)
    console.log(event.target.name)
    if (event.target.files.length > 0) {
      console.log(event.target.files)
      const file = event.target.files[0];
      this.kycForm.patchValue({
        fileSource: file
      });
      // this.kycForm.updateValueAndValidity();
      // console.log(this.kycForm)
      this.formData.append(event.target.id, file);
    }
  }

  saveKyc() {
    const val = this.kycForm.value;
    console.log("val", val);

    if(!this.kycForm.valid) {
      const val = this.kycForm.value;
      console.log("val", val);
      this.errorMessage = 'All fields are mandatory';
      return
    }
    this.formData.append('badge_number', val.badge_number);
    this.formData.append('white_plate_number', val.white_plate_number);
    this.formData.append('national_insurance_no', val.national_insurance_no);
    console.log(this.formData);

    this.kyc.driverKyc(this.formData)
    .subscribe({
      next:data => {
        console.log("data", data);
        this.router.navigateByUrl('/register/cab-info');
      },
      error:err => this.errorMessage = err.details
    })
    
  }

}
