import {Injectable, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {tap} from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})

export class RegisterService{
    // https://admin.furrybaxi.ml/v1
    // http://127.0.0.1:8000/v1
    private apiUrl = 'https://api.furrybaxi.ml/fb/v1';
    private otpId = '';

    constructor(private http: HttpClient) {}


    createDriver(fName: string, lName: string, email: string) {
        return this.http.post<any>(this.apiUrl+'/accounts/user', 
            {"f_name":fName, "l_name": lName, "role": "driver", "email": email})
        .pipe(
            tap((res) => {
                console.log("Response", res)
            })
        )
    }
    
    updateDriverLocation(id: string) {
        return this.http.post<any>(this.apiUrl+'/accounts/driver/locations', {"lid": "57e1d7ef-0bcc-4972-ad8b-828c9768f1a8"})
    }


    driverKyc(form) {
        return this.http.post<any>(this.apiUrl+'/accounts/driver/kyc', 
            form)
        .pipe(
            tap((res) => {
                console.log("Response", res)
            })
        )
    }

    bankKyc(form) {
        return this.http.post<any>(this.apiUrl+'/accounts/driver/banks', 
            form)
        .pipe(
            tap((res) => {
                console.log("Response", res)
            })
        )
    }

    cabKyc(form) {
        return this.http.post<any>(this.apiUrl+'/accounts/driver/cab', 
            form)
        .pipe(
            tap((res) => {
                console.log("Response", res)
            })
        )
    }

    uploadProfile(file) {
        return this.http.post<any>(this.apiUrl+'/accounts/upload', file);
    }
}