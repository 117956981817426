import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {RegisterService} from '../register.service';

@Component({
  selector: 'app-register-driver',
  templateUrl: './register-driver.component.html',
  styleUrls: ['./register-driver.component.css']
})
export class RegisterDriverComponent implements OnInit {

  driverForm: FormGroup
  errorMessage: string = "";

  constructor(private registerService: RegisterService,
              private router: Router) { }

  ngOnInit(): void {
    this.driverForm = new FormGroup({
      'fName': new FormControl(null, [Validators.required]),
      'lName': new FormControl(null, [Validators.required]),
      'email': new FormControl(null, [Validators.email]),
    })
  }
  
  createDriver() {
    if (!this.driverForm.valid) {
      this.errorMessage = 'All fields are mandatory';
      return
    }
    console.log(this.driverForm);
    console.log('Saved: ' + JSON.stringify(this.driverForm.value));
    const val = this.driverForm.value
    // createDriver
    this.registerService.createDriver(val.fName, val.lName, val.email)
    .subscribe({
      next: data => {
        console.log(data);
        this.router.navigateByUrl('/register/select-city');
      },
      error: err => this.errorMessage = 'Email already exists'
    });
    
  }

}
