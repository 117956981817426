import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ShareLocationComponent } from './share-location/share-location/share-location.component';


const routes: Routes = [
  {path:'shared', component: ShareLocationComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
