<div *ngIf="errorMessage"
     class="alert alert-danger">
  Error: {{ errorMessage }}
</div>

<div class="container furry_col">
    <div class="row align-items-center mt-4" >

        <div class="col-lg-6 " style="color: #E1CDA9;">
            <h4>Lorem Ipsum</h4>
            <span class="">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repellendus sed, natus earum eligendi aperiam facilis delectus, magni laboriosam voluptas iusto quod exercitationem dolorum ducimus. Vitae commodi unde aliquam repellat rerum eveniet quae in, eligendi magni
                voluptas esse saepe accusamus, facilis fugit eum incidunt et adipisci nostrum dolorum, explicabo consectetur sit!
            </span>
        </div>

        <div class="col-lg-6 card bg-white text-center mt-4" style="padding:40px 30px 60px 30px">
            <h4>What's the OTP?</h4>
                <p class="text-muted">Enter the otp sent to yourn number</p>
                <form novalidate
                (ngSubmit)="verifyOtp()"
                [formGroup]="otpVerifyForm">
                    <div class="d-flex flex-row">
                        <input type="phone" name="otp1"
                            #digitOne
                            formControlName="otp1" 
                            maxlength="1"
                            placeholder="1" class="form-control otp-input"
                            (keyup)="onDigitInput($event)">
                        <input type="phone"
                            #digitTwo
                            formControlName="otp2" 
                            placeholder="2" 
                            maxlength="1"
                            class="form-control otp-input"
                            (keyup)="onDigitInput($event)">
                        <input type="phone"
                            #digitThree
                            formControlName="otp3" 
                            placeholder="3" 
                            maxlength="1"
                            class="form-control otp-input"
                            (keyup)="onDigitInput($event)">
                        <input type="phone" 
                            #digitFour
                            formControlName="otp4" placeholder="4" class="form-control otp-input"
                            (keyup)="onDigitInput($event)">
                    </div>  
                    <input type="submit" value="Next" class="form-control mt-3" 
                    style="background-color: rgb(53, 51, 51); color: white;">
                </form>
                   
                
        </div>
    </div>
</div>