import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UploadService } from './upload-service';
import { RegisterService } from '../register.service';

@Component({
  selector: 'app-upload-profile',
  templateUrl: './upload-profile.component.html',
  styleUrls: ['./upload-profile.component.css']
})
export class UploadProfileComponent implements OnInit {

  // in app.component.ts
files: File[] = [];
errorMessage = '';

onSelect(event) {
  this.files.push(...event.addedFiles);
  console.log(this.files);
}

onRemove(event) {
  console.log(event);
  this.files.splice(this.files.indexOf(event), 1);
}

  constructor(private uploadSer: UploadService,
              private router: Router,
              private register: RegisterService
    ) { }

  ngOnInit(): void {
  }

  setProfile() {
    const formData = new FormData();
    formData.append('pic', this.files[0], this.files[0].name);
    this.register.uploadProfile(formData)
    .subscribe({
      next:data => {
        console.log(data);
        this.router.navigateByUrl('/register/add-details');
      },
      error: err => this.errorMessage = err.details
    })
    
    
  }

}
