import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgxDropzoneModule } from 'ngx-dropzone';
// import {MatSelectModule} from '@angular/material/select';

import { CreateOtpComponent } from './create-otp/create-otp.component';
import { VerifyOtpComponent } from './verify-otp/verify-otp.component';
import { RegisterDriverComponent } from './register-driver/register-driver.component';
import { SelectCityComponent } from './select-city/select-city.component';
import { AddDriverDetailsComponent } from './driver-details/add-driver-details/add-driver-details.component';
import { UploadProfileComponent } from './upload-profile/upload-profile.component';
import { BankInfoComponent } from './driver-details/bank-info/bank-info.component';
import { CarInfoComponent } from './driver-details/car-info/car-info.component'
import { CompleteComponent } from './complete/complete.component';


@NgModule({
  declarations: [
    CreateOtpComponent,
    VerifyOtpComponent,
    RegisterDriverComponent,
    SelectCityComponent,
    AddDriverDetailsComponent,
    UploadProfileComponent,
    BankInfoComponent,
    CarInfoComponent,
    CompleteComponent
  ],
  imports: [
    
    NgxDropzoneModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    // MatSelectModule,
    RouterModule.forChild([
      {
      path: 'register',
      data: { preload: false },
      children: [
        {
          path:'verify-otp',
          component: VerifyOtpComponent,
          data: { preload: false },
        },
        {
          path:'create-driver',
          component: RegisterDriverComponent,
          data: { preload: false },
        },
        {
          path:'select-city',
          component: SelectCityComponent,
          data: { preload: false },
        },
        {
          path:'add-details',
          component: AddDriverDetailsComponent,
          data: { preload: false },
        },
        {
          path:'upload-profile',
          component: UploadProfileComponent,
          data: { preload: false },
        },
        {
          path:'bank-info',
          component: BankInfoComponent,
          data: { preload: false },
        },
        {
          path:'cab-info',
          component: CarInfoComponent,
          data: { preload: false },
        },
        {
          path:'',
          component: CreateOtpComponent,
          data: { preload: false },
        },
        {
          path:'complete',
          component: CompleteComponent,
          data: { preload: false },
        },
      ]
    }
    ]),
    
  ]
})
export class RegistrationModule { }
