<div *ngIf="errorMessage"
     class="alert alert-danger">
  Error: {{ errorMessage }}
</div>

<div class="container furry_col">
    <div class="row align-items-center mt-4" >

        <div class="col-lg-6 " style="color: #E1CDA9;">
            <h4>Lorem Ipsum</h4>
            <span class="">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repellendus sed, natus earum eligendi aperiam facilis delectus, magni laboriosam voluptas iusto quod exercitationem dolorum ducimus. Vitae commodi unde aliquam repellat rerum eveniet quae in, eligendi magni
                voluptas esse saepe accusamus, facilis fugit eum incidunt et adipisci nostrum dolorum, explicabo consectetur sit!
            </span>
        </div>

        <div class="col-lg-6 card bg-white text-center mt-4" style="padding:40px 30px 60px 30px">
            <h4>Create Account</h4>
                
            <form class="text-center" novalidate
                (ngSubmit)="createDriver()"
                [formGroup]="driverForm"
                >
                <div class="row my-4 ">
                  <div class="col-md-6">
                    <input type="text"
                     formControlName="fName"   
                     class="form-control" id="fname" placeholder="First name">
                  </div>
                  <div class="col-md-6">
                    <input type="text"
                    formControlName="lName"
                     class="form-control" id="lName" placeholder="Last name">
                  </div>
                </div>
                <!-- <div class="form-group my-4">
                  <input type="date" 
                   formControlName="date"
                   class="form-control text-muted" id="date" placeholder="Enter the date">
                </div> -->
                <div class="form-group my-4">
                  <input type="email" 
                   formControlName="email" 
                   class="form-control" id="inputAddress2" placeholder="Email Address">
                </div>
                <input type="submit" value="Next" class="form-control mt-3" style="background-color: rgb(53, 51, 51); color: white;">
              </form>
             
        </div>
    </div>
</div>