<div *ngIf="errorMessage"
     class="alert alert-danger">
  Error: {{ errorMessage }}
</div>

<div class="container mt-5 px-4 pb-4 ">
    <div class="card p-4">
        <h3><span>Car Details</span></h3>

        <form class="text-muted" novalidate
          [formGroup]="carForm">
            <div class="row my-4 ">
              <div class="col-md-6">
                <label for="make_id" class="text-muted">
                    Make
                </label>
                <input type="text" class="form-control" 
                  id="make_id" placeholder="Make"
                  formControlName="make_id"
                  >
              </div>
              <div class="col-md-6">
                <label for="vehicle_schedule" class="text-muted">
                  Vehicle Schedule
                  (Insurance Supporting Documents)
                </label>
                <input type="file" class="form-control" 
                  id="vehicle_schedule" 
                  placeholder="Vehicle Schedule"
                  formControlName="vehicle_schedule"
                  (change)="onFileChange($event)"
                  >
              </div>
            </div>
            <div class="row my-4 ">

                <div class="col-md-6">
                  <label for="model_id" class="text-muted">Model</label>
                  <input type="text" class="form-control" 
                   id="model_id" 
                   placeholder="Model"
                   formControlName="model_id"
                    >
                </div>
                <div class="col-md-6">
                  <label for="driver_schedule" class="text-muted">
                    Bank Statement
                  </label>
                  <input type="file" class="form-control" 
                  id="driver_schedule"
                   formControlName="driver_schedule"
                   placeholder="Driver Schedule"
                   (change)="onFileChange($event)">
                </div>
            </div>

            <div class="row my-4 ">
                
                <div class="col-md-6">
                  <label for="color_id" class="text-muted">Color</label>
                  <input type="text" class="form-control" 
                   id="color_id" 
                   placeholder="Color"
                   formControlName="color_id"
                    >
                </div>
                <div class="col-md-6">
                  <label for="mot_test_certificate" class="text-muted">
                    MOT Test Certificate
                  </label>
                  <input type="file" class="form-control" 
                   id="mot_test_certificate"
                   formControlName="mot_test_certificate"
                   placeholder="MOT Test Certificate"
                   (change)="onFileChange($event)">
                </div>
              </div>

              <div class="row my-4 ">
                
                <div class="col-md-6">
                  <label for="model_id" class="text-muted">Insurance Certificate</label>
                  <input type="file" class="form-control" 
                   id="insurance_certificate" 
                   placeholder="Model"
                   formControlName="insurance_certificate"
                   (change)="onFileChange($event)"
                    >
                </div>
                <div class="col-md-6">
                  <label for="phv_license" class="text-muted">
                    PHV Licence
                  </label>
                  <input type="file" class="form-control" 
                  id="phv_license"
                   formControlName="phv_license"
                   placeholder="PHV Licence"
                   (change)="onFileChange($event)">
                </div>
              </div>

              <div class="row my-4 ">
                
                <div class="col-md-6">
                  <label for="ha_permission_letter" class="text-muted">
                      Hire Agreement or Permission Letter
                    </label>
                  <input type="file" class="form-control" 
                   id="ha_permission_letter" 
                   placeholder="Model"
                   formControlName="ha_permission_letter"
                   (change)="onFileChange($event)"
                    >
                </div>
                <div class="col-md-6">
                  <label for="log_book" class="text-muted">
                    Log Book
                  </label>
                  <input type="file" class="form-control" 
                   id="log_book"
                   formControlName="log_book"
                   placeholder="Log Book"
                   (change)="onFileChange($event)">
                </div>
              </div>
      
                <input type="button"
                (click)="saveKyc()"

                class="form-control" value="Next" 
                style="background-color: rgb(225, 224, 224); width: 500px; margin: auto;">
        </form>

    </div>
</div>