import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RegisterService } from '../register.service';

@Component({
  selector: 'app-select-city',
  templateUrl: './select-city.component.html',
  styleUrls: ['./select-city.component.css']
})
export class SelectCityComponent{
  errorMessage = '';

  constructor(private registerService: RegisterService,
              private router: Router) { }

  selectCity(city: string) {
    this.registerService.updateDriverLocation("random")
    .subscribe({
      next:data => {
        console.log("data", data);
        this.router.navigateByUrl('/register/add-details');
      },
      error: err => this.errorMessage = err
    });
    
    
  }

}

// .subscribe({
//   next: data => {
//     this.router.navigateByUrl('/register/select-city');
//   },
//   error: err => this.errorMessage = err
// });