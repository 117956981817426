import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RegisterService } from '../../register.service';

@Component({
  selector: 'app-bank-info',
  templateUrl: './bank-info.component.html',
  styleUrls: ['./bank-info.component.css']
})
export class BankInfoComponent implements OnInit {
  bankForm: FormGroup;
  formData = new FormData();
  clicked = false;
  errorMessage: string= '';

  constructor(private kyc: RegisterService,
              private router: Router
    ) { }

  ngOnInit(): void {
    this.bankForm = new FormGroup({
      'account_number': new FormControl(null, [Validators.required]),
      'name': new FormControl(null, [Validators.required]),
      'sort_code': new FormControl(null, [Validators.required]),
      'account_statement': new FormControl(null, [Validators.required]),
    })
  }

  onFileChange(event) {
    const file = (event.target as HTMLInputElement).files[0];
    
    console.log("file", file)
    console.log(event.target.name)
    if (event.target.files.length > 0) {
      console.log(event.target.files)
      const file = event.target.files[0];
      this.bankForm.patchValue({
        fileSource: file
      });
      // this.kycForm.updateValueAndValidity();
      // console.log(this.kycForm)
      this.formData.append(event.target.id, file);
    }
  }

  saveKyc() {
    // this.clicked = true;
    if(!this.bankForm.valid) {
      this.errorMessage = 'All fields are mandatory';
      return
    }
    const val = this.bankForm.value;
    this.formData.append('account_number', val.account_number);
    this.formData.append('name', val.name);
    this.formData.append('sort_code', val.sort_code);
    console.log(this.formData);
    this.kyc.bankKyc(this.formData)
    .subscribe({
      next:data => {
        console.log("data", data);
        this.router.navigateByUrl('/register/complete');
      },
      error: err => this.errorMessage = err
  });
}
}
