import {Injectable, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {tap} from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})

export class OtpService{
    // http://127.0.0.1:8000/v1
    // https://api.furrybaxi.ml/fb/v1
    private apiUrl = 'https://api.furrybaxi.ml/fb/v1';
    private otpId = '';

    constructor(private http: HttpClient) {}


    getOtp(phone: string, country_code: string) {
        if (!phone || !country_code) {
            return;
          }
        return this.http.post<any>(this.apiUrl+'/auth/signup', {phone, country_code})
        .pipe(
            tap((res) => {
                console.log("Response", res)
            })
        )  
    }

    setOtpId(otpId: string) {
        this.otpId = otpId;
        console.log(this.otpId, 'otp id');
    }

    verifyOtp(otpId: string, otp: string) {
        return this.http.post<any>(this.apiUrl+'/auth/verify', {"otp_id":otpId, "otp":otp})
        .pipe(
            tap((res) => {
                console.log("Response", res)
            })
        )
    }

  setAccessToken(token: string) {
    localStorage.setItem('access_token', token);
  }

  setRefreshToken(token: string) {
    localStorage.setItem('refresh_token', token);
  }

  getCurrentUser() {
    return this.http.get<any>(this.apiUrl+'/accountsme')
    .pipe(
        tap(
        res => console.log(res)
        ))
  }

  getKycStatus() {
    return this.http.get<any>(this.apiUrl+'/accounts/driver/kyc/check')
    .pipe(
        tap(
        res => console.log(res)
        ))
  }

}