import { AfterViewInit, Component } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError, NavigationCancel } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'furrybaxi';
  lat: number = 51.678418;
  lng: number = 7.809007;

  constructor(private router: Router) {
    router.events.subscribe((routerEvent: Event) => {
      this.checkRouterEvent(routerEvent);
  });
}

checkRouterEvent(routerEvent: Event): void {
  if (routerEvent instanceof NavigationStart) {
    console.log("routing starts");
  }

  if (routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError) {
        console.log("routing stops");
  }
}

}
