import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import {OtpService} from '../otp-service';

@Component({
  selector: 'app-create-otp',
  templateUrl: './create-otp.component.html',
  styleUrls: ['./create-otp.component.css']
})
export class CreateOtpComponent implements OnInit, AfterViewInit {

  otpForm: FormGroup
  loading = true;
  errorMessage: string = '';
  items = ["44", "91"]

  ngOnInit(): void {
    this.otpForm.get('cc').setValue(this.items[0]) 
  }

  constructor(private otpService: OtpService,
              private router: Router,
              fb: FormBuilder) 
              {
                this.otpForm = fb.group({
                  phone: ['', Validators.required],
                  cc: ['', Validators.required]
                })

               }


  sendOtp() {
    if (!this.otpForm.valid) {
      this.errorMessage = 'All fields are mandatory';
      return
    }
    const val = this.otpForm.value;
    
    this.otpService.getOtp(val.phone, val.cc)
    .subscribe( {
      next: data => {
          console.log("data", data);
          localStorage.setItem('furry_otp_id', data.otp_id);
          this.router.navigateByUrl('/register/verify-otp');
      },
      error: err => this.errorMessage = err
    });
  }

  ngAfterViewInit() {
    this.loading = false
  }
}
