import {Injectable, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {tap} from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})

export class UploadService{
    // https://admin.furrybaxi.ml/v1
    // https://api.furrybaxi.ml/fb/v1/
    private apiUrl = 'https://admin.furrybaxi.ml/v1/accounts/upload';
    private otpId = '';

    constructor(private http: HttpClient) {}

    uploadProfile(file) {
        return this.http.post<any>(this.apiUrl, file);
    }

}