<div *ngIf="errorMessage"
     class="alert alert-danger">
  Error: {{ errorMessage }}
</div>

<div class="container furry_col">
    <div class="row align-items-center mt-4" >

        <div class="col-lg-6 " style="color: #E1CDA9;">
            <h4>Lorem Ipsum</h4>
            <span class="">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repellendus sed, natus earum eligendi aperiam facilis delectus, magni laboriosam voluptas iusto quod exercitationem dolorum ducimus. Vitae commodi unde aliquam repellat rerum eveniet quae in, eligendi magni
                voluptas esse saepe accusamus, facilis fugit eum incidunt et adipisci nostrum dolorum, explicabo consectetur sit!
            </span>
        </div>

        <div class="col-lg-6 card bg-white text-center mt-4" style="padding:40px 30px 60px 30px">
            <h4>Select City</h4>
                
            <form class="">
                <div class="row my-4 ">
                  <div class="col-md-6">
                    <!-- <label for="inputEmail4">Email</label> -->
                    <input type="button" 
                    (click) = "selectCity('London')" 
                    class="form-control py-5 text-muted" id="inputEmail4" value="London">
                  </div>
                  <div class="col-md-6">
                    <!-- <label for="inputPassword4">Password</label> -->
                    <input type="button"
                    (click) = "selectCity('Manchester')" 
                    class="form-control py-5 text-muted" id="inputPassword4" value="Manchester">
                  </div>
                </div>
                <div class="row my-4 ">
                  <div class="col-md-6">
                    <!-- <label for="inputAddress">Address</label> -->
                    <input 
                    (click) = "selectCity('Beckenham')" 
                    type="button" 
                    class="form-control py-5 text-muted" id="inputAddress" value="Beckenham">
                  </div>
                  <div class="col-md-6">
                    <!-- <label for="inputAddress2">Address 2</label> -->
                    <input type="button" 
                    (click) = "selectCity('Beyswater')" 
                    class="form-control py-5 text-muted" id="inputAddress2" value="Beyswater">
                  </div>
                </div>
              </form>
        </div>
    </div>
</div>