import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RegistrationModule } from './registration/registration.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxDropzoneModule } from 'ngx-dropzone';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { JwtTokenInterceptor } from './registration/jwt-interceptor';

import { LoaderService } from './services/loader.service';
import { LoaderInterceptor } from './interceptors/loader-interceptor.service';
import { MyLoaderComponent } from './my-loader/my-loader.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {AgmCoreModule} from '@agm/core';
import { ShareLocationComponent } from './share-location/share-location/share-location.component';
// import { NgHttpLoaderModule } from 'ng-http-loader';


@NgModule({
  declarations: [
    AppComponent,
    MyLoaderComponent,
    ShareLocationComponent
  ],
  imports: [
    NgxDropzoneModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCntqqoVossnBgmImNCle-kDjAPaYrnXEA'
    }),
    // CommonModule,
    BrowserModule,
    AppRoutingModule,
    RegistrationModule,
    HttpClientModule,
    BrowserAnimationsModule,
    // NgHttpLoaderModule.forRoot(),
  ],
  providers: [
    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtTokenInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
