import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RegisterService } from '../../register.service';

@Component({
  selector: 'app-car-info',
  templateUrl: './car-info.component.html',
  styleUrls: ['./car-info.component.css']
})
export class CarInfoComponent implements OnInit {
  carForm: FormGroup;
  formData = new FormData();
  clicked = false;
  errorMessage: string = '';

  constructor(private kyc: RegisterService,
              private router: Router) { }

  ngOnInit(): void {
    this.carForm = new FormGroup({
      'log_book': new FormControl(null, [Validators.required]),
      'ha_permission_letter': new FormControl(null, [Validators.required]),
      'phv_license': new FormControl(null, [Validators.required]),
      'insurance_certificate': new FormControl(null, [Validators.required]),
      'mot_test_certificate': new FormControl(null, [Validators.required]),
      'color_id': new FormControl(null, [Validators.required]),
      'driver_schedule': new FormControl(null, [Validators.required]),
      'model_id': new FormControl(null, [Validators.required]),
      'vehicle_schedule': new FormControl(null, [Validators.required]),
      'make_id': new FormControl(null, [Validators.required]),
    })
  }

  onFileChange(event) {
    const file = (event.target as HTMLInputElement).files[0];
    
    console.log("file", file)
    console.log(event.target.name)
    if (event.target.files.length > 0) {
      console.log(event.target.files)
      const file = event.target.files[0];
      this.carForm.patchValue({
        fileSource: file
      });
      // this.kycForm.updateValueAndValidity();
      // console.log(this.kycForm)
      this.formData.append(event.target.id, file);
    }
  }

  saveKyc() {
    // this.clicked = true;
    if(!this.carForm.valid) {
      this.errorMessage = 'All fields are mandatory';
      return
    }
    const val = this.carForm.value;
    console.log("val", val);
    this.formData.append('make_id', val.make_id);
    this.formData.append('color_id', val.color_id);
    this.formData.append('model_id', val.model_id);
    console.log(this.formData);
    this.kyc.cabKyc(this.formData)
    .subscribe({
      next: data => {
        console.log("data", data);
        this.router.navigateByUrl('/register/bank-info');
      },
      error:err => this.errorMessage = err
    });

    
  }

}
