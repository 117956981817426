<div *ngIf="errorMessage" class="alert alert-danger">
  Error: {{ errorMessage }}
</div>
<div class="container mt-5 px-4 pb-4 ">
  <div class="card p-4">
    <!-- <h5>Complete your</h5>
        <h5><b>Partner Profile</b></h5>
        <p class="text-muted mb-4">Fill the following</p> -->
    <h3><span>Driver Details</span></h3>

    <form class="text-muted" novalidate [formGroup]="kycForm">
      <div class="row my-4 ">
        <div class="col-md-6">
          <label for="profile_pic" class="text-muted">Profile Image</label>
          <input type="file" class="form-control" id="profile_pic" placeholder="Profile Photo"
            formControlName="profile_pic" (change)="onFileChange($event)">
        </div>
        <div class="col-md-6">
          <label for="dvla_ec_check_code" class="text-muted">
            DLVA Electronic Counterpart Check Code
          </label>
          <input type="file" class="form-control" id="dvla_ec_check_code"
            placeholder="DLVA Electronic Counterpart Check Code" formControlName="dvla_ec_check_code"
            (change)="onFileChange($event)">
        </div>
      </div>
      <div class="row my-4 ">
        <div class="col-md-6">
          <label for="id_proof" class="text-muted">Identification Proof</label>
          <input type="file" class="form-control" id="id_proof" placeholder="Proof of Identity"
            formControlName="id_proof" (change)="onFileChange($event)">
        </div>
        <div class="col-md-6">
          <label for="national_insurance_no" class="text-muted">
            National Insurance Number
          </label>
          <input type="text" class="form-control" id="national_insurance_no" formControlName="national_insurance_no"
            placeholder="National Insurance Number">
        </div>
      </div>

      <div class="row my-4 ">
        <div class="col-md-6">
          <label for="driving_license" class="text-muted">Driving License</label>
          <input type="file" class="form-control" id="driving_license" placeholder="DLVA Plastic Driving Licence"
            formControlName="driving_license" (change)="onFileChange($event)">
        </div>
        <div class="col-md-6">
          <label for="badge_number" class="text-muted">Badge Number</label>
          <input type="text" class="form-control" id="badge_number" formControlName="badge_number"
            placeholder="Badge Number">
        </div>
      </div>

      <div class="row my-4 ">
        <div class="col-md-6">
          <label for="ph_pco_license" class="text-muted">Ph PCO License</label>
          <input type='file' id="ph_pco_license" class="form-control" formControlName="ph_pco_license"
            (change)="onFileChange($event)">

        </div>
        <div class="col-md-6">
          <label for="white_plate_number" class="text-muted">White Plate Number</label>
          <input type="text" class="form-control" id="white_plate_number" formControlName="white_plate_number"
            placeholder="White Plate Number (NSL)">
        </div>
      </div>
      <br>

      <input type="button" (click)="saveKyc()" class="form-control" value="Next"
        style="background-color: rgb(225, 224, 224); width: 500px; margin: auto;">
    </form>

  </div>
</div>