<div *ngIf="errorMessage"
     class="alert alert-danger">
  Error: {{ errorMessage }}
</div>

<div class="container mt-5 px-4 pb-4 ">
    <div class="card p-4">
        <h3><span>Bank Details</span></h3>

        <form class="text-muted" novalidate
          [formGroup]="bankForm">
            <div class="row my-4 ">
              <div class="col-md-6">
                <label for="name" class="text-muted">Name Of Account Holder</label>
                <input type="text" class="form-control" 
                  id="name" placeholder="Name"
                  formControlName="name"
                  >
              </div>
              <div class="col-md-6">
                <label for="account_number" class="text-muted">
                  Account Number
                </label>
                <input type="text" class="form-control" 
                  id="account_number" 
                  placeholder="Account Number"
                  formControlName="account_number"
                  >
              </div>
            </div>
            <div class="row my-4 ">
                <div class="col-md-6">
                  <label for="sort_code" class="text-muted">Sort Code</label>
                  <input type="text" class="form-control" id="sort_code" 
                   placeholder="Sort Code"
                   formControlName="sort_code"

                    >
                </div>
                <div class="col-md-6">
                  <label for="bank_statement" class="text-muted">
                    Bank Statement
                  </label>
                  <input type="file" class="form-control" id="account_statement"
                   formControlName="account_statement"
                   placeholder="Bank Statement"
                   (change)="onFileChange($event)">
                </div>
              </div>
      
                <input type="button"
                (click)="saveKyc()"

                class="form-control" value="Next" style="background-color: rgb(225, 224, 224); width: 500px; margin: auto;">
        </form>

    </div>
</div>